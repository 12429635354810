import React from 'react';
import Layout from "../components/layout/layout";
import Helmet from "react-helmet";


export default ({location}) => (
  <Layout location={location}>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <div className="bg-washed-red pv5 flex flex-column items-center">
      <h1 className="db f1 display fw1">Aviso Legal</h1>
    </div>
    <div className="lh-copy f4 serif mw8 center">
      <p>LABIAL10 se reserva el derecho de llevar a cabo sin necesidad de previo aviso cuantas modificaciones considere oportunas en cualquiera de sus blogs o sitios webs. Esto incluye la posibilidad de cambiar, suprimir o añadir la totalidad de contenidos así como de servicios que a través de ellos se presten y la forma en la que los mismos aparezcan presentados o localizados en su web.</p>
      <h2 className="sans-serif">Propiedad intelectual e industrial</h2>
      <ul>
        <li>LABIAL10 por si o en calidad de cesionaria, es propietaria de todos los derechos de propiedad intelectual e industrial de sus páginas webs y de los elementos que en ellas se contienen, entre los que cabe mencionar software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, etc., cuya titularidad corresponde a LABIAL10 o a sus licenciantes. Todos los derechos reservados.</li>
        <li>La reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, del total o de parte de los contenidos de esta página web y de los blogs que pertenecen a la red de blogs LABIAL10 para fines comerciales, en cualquier tipo soporte y por cualquier medio técnico, queda expresamente prohibida en virtud de lo dispuesto en los artículos 8 y 32.1 de la Ley de Propiedad Industrial si LABIAL10 no lo autoriza.</li>
        <li>El USUARIO adquiere el compromiso de respetar los derechos de Propiedad Intelectual e Industrial titularidad de LABIAL10. Se le autoriza a visualizar los elementos del Site e incluso a imprimirlos, copiarlos y almacenarlos tanto en el disco duro de su ordenador como en cualquier otro soporte físico siempre que el uso al que lo vaya a destinar sea, única y exclusivamente, personal y privado.</li>
        <li>El USUARIO se abstendrá de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviese instalado en las páginas de LABIAL10.</li>
        <li>Del mismo modo, LABIAL10 es una marca registrada.</li>
      </ul>
      <h2 className="sans-serif">Usuarios</h2>
      <p>Acceder y/o usar los blogs y páginas webs de LABIAL10 atribuye la condición de USUARIO, el cual, una vez realizado el acceso y/o uso aceptará las Condiciones Generales de Uso que aquí se reflejan. Las mencionadas Condiciones se aplicarán con independencia de las Condiciones Generales de Contratación que hayan de cumplirse obligatoriamente en su caso.</p>
      <h2 className="sans-serif">Uso del portal</h2>
      <ul>
        <li>Tanto el Site de LABIAL10 como sus respectivos blogs facilitan el acceso a multitud de informaciones, servicios o datos (en adelante, “los contenidos”) en Internet pertenecientes a LABIAL10 o a sus licenciantes, a los que el USUARIO puede ACCEDER. De esta forma, el USUARIO asume la responsabilidad del uso del Site. Tal responsabilidad se extiende al registro que se precise para acceder a concretos servicios o contenidos. En dicho registro el USUARIO se responsabilizará de forma única de que la información que aporta sea veraz y lícita. Al registrarse, el USUARIO puede recibir una contraseña de la que será responsable, exigiéndosele el compromiso de que haga un uso diligente y confidencial de la misma.</li>
        <li>El USUARIO se compromete también a usar adecuadamente los contenidos y servicios (como por ejemplo servicios de chat, foros de discusión o grupos de noticias) que LABIAL10  le ofrece mediante sus blogs y con carácter enunciativo que no limitativo, a no emplearlos para:
          <ul>
            <li>
              Incidir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público
            </li>
            <li>Divulgar contenidos o propaganda con un carácter  racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o que atente contra los derechos humanos</li>
            <li>Provocar daños en los sistemas físicos y lógicos de LABIAL10 o de  sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos u otros sistemas físicos o lógicos que puedan ocasionar los daños anteriormente mencionados</li>
            <li>Hacer el intento de acceder y, llegado el caso, utilizar las cuentas de correo electrónico utilizadas por otros usuarios y modificar o manipular sus mensajes. LABIAL10 se reserva el derecho de retirar todo comentario o aportación susceptible de vulnerar el respeto a la dignidad de la persona, que sea discriminatorio, xenófobo, racista, pornográfico, atentatorio  contra la juventud o la infancia, el orden o la seguridad pública o que considere inadecuado para su publicación</li>
            <li>En ningún caso se hará LABIAL10 responsable de las opiniones que viertan los usuarios a través de los foros, chats, u otros mecanismos  de participación como puedan ser los comentarios en los blogs</li>
          </ul>
        </li>
      </ul>
      <h2 className="sans-serif">Protección de datos</h2>
      <p>LABIAL10, en cumplimiento de  con las directrices de la Ley Orgánica 15/1999 de 13 de diciembre de Protección de Datos de Carácter Personal, el Real Decreto 1720/2007 de 21 de diciembre por el que se aprueba el Reglamento de desarrollo de la Ley Orgánica y el resto de normativa vigente en cada momento,  vela porque se garantice un adecuado uso y tratamiento de los datos personales del usuario. Más información en Política de Privacidad.</p>
      <h2 className="sans-serif">Exclusión de garantías y responsabilidad</h2>
      <p>En ningún caso se hará responsable LABIAL10 de los daños y perjuicios de diversa naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los contenidos, pese a haber adoptado todas las medidas tecnológicas existentes a su alcance para evitarlo.</p>
      <h2 className="sans-serif">Modificaciones</h2>
      <p>LABIAL10 podrá efectuar sin previo aviso todas las modificaciones que estime oportunas en su Site y blogs. Esto implica que podrá cambiar, suprimir o añadir  los contenidos y servicios que se presten a través de ella y  la forma en la que éstos aparezcan presentados o localizados en su Site.</p>
      <h2 className="sans-serif">Enlaces a otros sites</h2>
      <p>Si se disponen enlaces o hipervínculos hacía otros sitios de Internet,LABIAL10  no desempeñará ningún tipo de control sobre dichos sitios y contenidos. Bajo ningún concepto LABIAL10 se responsabilizará  por los contenidos de algún enlace que pertenezca a un sitio web ajeno, ni tampoco garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información que se contenga en ninguno de los mencionados hipervínculos u otros sitios de Internet. Asimismo, la inclusión de estas conexiones externas no supondrá ningún tipo de asociación, fusión o participación con las entidades a las que se conecte.</p>
      <h2 className="sans-serif">Generalidades</h2>
      <p>LABIAL10 perseguirá la falta de cumplimiento de las presentes condiciones así como de cualquier uso indebido de su Site y ejercitará todas las acciones civiles y penales que el Derecho le proporcione.</p>
      <h2 className="sans-serif">Legislación aplicable y jurisdicción</h2>
      <p>La relación entre LABIAL10 y el USUARIO estará regida por la normativa española vigente, sometiéndose a los Juzgados y Tribunales españoles, para cualquier controversia que pudiera derivarse.</p>
    </div>
  </Layout>
)
